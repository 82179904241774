<template>
  <v-container class="sheet">
    <v-layout row wrap>
      <v-flex>
        <h1 class="header">{{ brandNameDisplayText }}</h1>
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <div class="button-container">
          <v-btn
            v-if="!isModeMyCompany"
            :id="`brand-form-button-cancel-action`"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            {{ isModeView ? 'All Brands' : 'Cancel' }}
          </v-btn>

          <v-btn
            v-if="isModeEdit"
            :id="`brand-button-save-changes`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Changes
          </v-btn>

          <v-btn
            v-if="isModeAdd"
            :id="`brand-button-save-brand`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="createBrand"
          >
            Save Brand
          </v-btn>

          <v-btn
            v-if="isModeView"
            :id="`brand-button-edit-brand`"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Brand
          </v-btn>
        </div>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs4>
        <CRInput
          v-model="brand.brandName"
          :disabled="isModeView"
          label="Brand Name"
          placeholder="Enter brand name"
          style="max-width: 400px"
        />
      </v-flex>
      <v-flex xs4>
        <CRSelect
          v-model="brand.parentBrandId"
          :disabled="isModeView"
          :items="parentBrands"
          label="Parent Brand"
          item-text="label"
          item-value="id"
          placeholder="Please select the parent brand"
          style="max-width: 400px; margin-left: 20px;"
        />
      </v-flex>
    </v-layout>

    <div v-if="audioFields.length">
      <v-layout
        v-for="(field, fieldIndex) in audioFields"
        :key="fieldIndex"
        row
        wrap
        align-center
        style="justify-content: right"
        class="audio-row"
      >
        <v-flex order-xs1 xs6 sm12 md4>
          <b>{{ field.label }}:</b>
        </v-flex>
        <v-flex order-xs3 order-sm2 xs12 sm6 md5>
          <mini-audio
            v-if="field.value"
            :audio-source="field.value"
            :autoplay="false"
            :style="
              $cr.breakpoint.mdAndUp
                ? 'margin-right: auto; margin-left: auto;'
                : 'margin: 0;'
            "
            class="call-player"
          />
          <div v-else>
            <p
              style="
                padding-top: 8px;
                height: 40px;
                margin-top: 14px;
                margin-bottom: 14px;
              "
              :style="$cr.breakpoint.mdAndUp ? 'text-align: center' : ''"
            >
              {{ isModeAdd ? 'Add a Recording' : 'No Recording Found' }}
            </p>
          </div>
        </v-flex>
        <v-spacer />
        <v-flex order-xs2 order-sm3 xs6 md3>
          <v-layout row justify-end>
            <v-btn
              v-if="field.value"
              :disabled="isModeView"
              color="primary"
              outline
              @click="deleteRecording(field.key)"
            >
              Delete
            </v-btn>
            <input
              :id="`brand-audio-recording-upload-input-${field.key}`"
              accept=".mp3,audio/*"
              class="upload-input"
              type="file"
              style="display: none"
              :disabled="isModeView"
              @input="uploadRecording($event, field.key)"
            />
            <v-btn
              color="primary"
              :disabled="isModeView"
              @click="handleUpload(field.key)"
            >
              {{ field.value ? 'Edit' : 'Add' }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import brands from '@/services/brands'
import { deepClone } from '@/utils/deepClone'
import { capitalize } from '@/utils/string'

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle}`,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      brand: {
        brandName: null,
        brandId: null,
        recordedIntroMsg: null,
        recordedDateMsg: null,
        recordedTimeMsg: null,
        recordedAdditionalMsg1: null,
        recordedAdditionalMsg2: null,
        recordedAdditionalMsg3: null,
        recordedAdditionalMsg4: null,
        recordedAdditionalMsg5: null,
        recordedBooking: null,
        recordedLimoAndPartybus: null,
        recordedLocateADriver: null,
        recordedBilling: null,
        recordedOther: null,
        menuWordBilling: null,
        menuWordLimoAndPartybus: null,
        menuWordDriverLocation: null,
        menuWordOtherInquiries: null,
        recordedMenuConfirmation: null,
        recordedMenuCallbackOrVm: null,
        holdMusic1: null,
        holdMusic2: null,
        holdMusic3: null,
        holdMusic4: null,
        holdMusic5: null,
        holdSales: null,
        holdBilling: null,
        holdOperations: null,
        holdAgents: null,
        holdTransfer: null,
        genericVoicemail: null,
        customerSuccessVoicemail: null,
        salesVoicemail: null,
        operationsVoicemail: null,
        partnershipsVoicemail: null,
        billingVoicemail: null,
        enterpriseVoicemail: null,
        vipVoicemail: null,
        sdrManagerVoicemail: null,
        agentVoicemail: null,
        january: null,
        february: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        august: null,
        september: null,
        october: null,
        november: null,
        december: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
        fifth: null,
        sixth: null,
        seventh: null,
        eighth: null,
        ninth: null,
        tenth: null,
        eleventh: null,
        twelfth: null,
        thirteenth: null,
        fourteenth: null,
        fifteenth: null,
        sixteenth: null,
        seventeenth: null,
        eighteenth: null,
        ninteenth: null,
        twentieth: null,
        twentyFirst: null,
        twentySecond: null,
        twentyThird: null,
        twentyFourth: null,
        twentyFifth: null,
        twentySixth: null,
        twentySeventh: null,
        twentyEighth: null,
        twentyNinth: null,
        thirtieth: null,
        thirtyFirst: null,
        recordedMenuQuoteOptions: null,
        recordedMenuQuoteEnterNumber: null,
        recordedMenuReservationEnterNumber: null,
        recordedMenuCorrectOrReEnter: null,
        recordedMenuWeHaveYourCallBackNumberAs: null,
        recordedMenuKeepYourCallBackNumber: null,
        recordedMenuIDidNotCatchThat: null,
        recordedPleaseLeaveAMessageAtTheBeep: null,
        recordedGoodBye: null,
        recordedCallYouBackShortly: null,
        recordedIHeard: null,
        recordedYouAreThe: null,
        recordedCallerInLine: null,
        recordedTheEstimatedHoldTimeIs: null,
        recordedMinutes: null,
        recordedMinute: null,
        callbackZero: null,
        callbackOne: null,
        callbackTwo: null,
        callbackThree: null,
        callbackFour: null,
        callbackFive: null,
        callbackSix: null,
        callbackSeven: null,
        callbackEight: null,
        callbackNine: null,
        callbackHoldMusic1: null,
        callbackHoldMusic2: null,
        callbackHoldMusic3: null,
        callbackHoldMusic4: null,
        preferToReceiveACallback: null,
        weHaveCapturedYourCallback: null,
        callbackCorrectPress1: null,
        addAnExtensionNumber: null,
        enterYourExtensionNumber: null,
        capturedYourExtensionNumber: null,
        extensionCorrectPress1: null,
        agentWillCallYouBackExtensionNumber: null,
        callBackAtADifferentNumber: null,
        enterYourPhoneNumber: null,
        goBackToHold: null,
        weHaveReceivedYourRequestToBeCalledBack: null,
        thisIsYourRequestedCallbackFromCharterup: null,
        spanishLineRequest: null,
        spanishMainMenu: null,
        spanishReservationsMenu: null,
        spanishHoldAgent: null,
        spanishGenericVoicemail: null,
        spanishPleaseEnterExtension: null,
        spanishExtensionDoesNotExist: null,
        spanishExtensionDidNotCatch: null,
        parentBrandId: null
      },
      loading: false,
      disabled: true,
      updatedFields: [],
      parentBrands: [],
    }
  },
  computed: {
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeMyCompany() {
      return this.mode === 'myCompany'
    },
    pageTitle() {
      return this.brand?.brandName || 'Brand'
    },
    brandNameDisplayText() {
      const addBrandText = this.isModeAdd ? 'Add Brand' : ''
      return this.brand.brandName || addBrandText
    },
    saveButtonDisplayText() {
      return this.isModeEdit ? 'Save Changes' : 'Save'
    },
    audioFields() {
      let fields = []
      if (this.brand) {
        let brand = deepClone(this.brand)
        delete brand.brandId
        delete brand.brandName
        delete brand.parentBrandId
        for (const [key, value] of Object.entries(brand)) {
          let object = {}
          object.key = key
          object.value = value
          let label = ''
          key.split(/(?=[A-Z])/).forEach((word) => {
            if (word !== 'recorded') {
              label = label.length
                ? `${label} ${capitalize(word)}`
                : capitalize(word)
            }
          })
          object.label = label
          fields.push(object)
        }
      }
      return fields
    },
  },
  async mounted() {
    const parentBrandsResponse = await brands.getParentBrands()
    this.parentBrands = parentBrandsResponse.data.parentBrands

    if (!this.isModeAdd) {
      const brandResponse = await brands.getBrand(this.$route.params.id)
      this.brand = brandResponse.data.brand
    }
  },
  methods: {
    handleUpload(key) {
      document
        .getElementById(`brand-audio-recording-upload-input-${key}`)
        .click()
    },
    async uploadRecording(event, key) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      const params = {
        payload: formData,
      }
      const uploadResult = await brands.uploadAudioFile(params)
      if (uploadResult.status === 200) {
        if (this.isModeEdit) {
          this.updatedFields.push({ key: key, previousValue: this.brand[key] })
        }
        this.brand[key] = uploadResult.data.url
      }
    },
    async deleteRecording(key) {
      if (this.isModeEdit) {
        this.updatedFields.push({ key: key, previousValue: this.brand[key] })
      }
      this.brand[key] = ''
    },
    async submit() {
      try {
        await brands.updateBrand(this.brand)
        this.updatedFields = []
        this.$router.push({
          name: 'brands.view',
          params: { id: this.brand.brandId },
        })
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Brand changes saved.',
        })
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error saving brand changes.',
        })
      }
    },
    async createBrand() {
      try {
        const creationResponse = await brands.createBrand(this.brand)
        this.brand = creationResponse.data.brand
        this.$router.push({
          name: 'brands.view',
          params: { id: this.brand.brandId },
        })
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Brand changes saved.',
        })
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error saving brand changes.',
        })
      }
    },
    enableEdit() {
      this.$router.push({
        name: 'brands.edit',
        params: { id: this.brand.brandId },
      })
      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push({ name: 'brands' })
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }
      this.backToTable()
    },
    disableAndRevert() {
      this.updatedFields.forEach((field) => {
        this.brand[field.key] = field.previousValue
      })
      this.updatedFields = []
      this.$router.push({
        name: 'brands.view',
        params: { id: this.brand.brandId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet {
  padding-top: 30px !important;
}

.header {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}

.audio-row {
  padding: 4px 0;
  border-top: 1px solid $gray-light;

  .call-player {
    background-image: none !important;
    background: $primary;
    height: 40px;
    ::v-deep span {
      color: white !important;
    }
    .icon-stopcircle-fill {
      display: none !important;
    }
    ::v-deep .slider .process {
      background: $gray-medium-light !important;
    }
  }
}

.upload-input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
}

.button-container {
  margin-top: -10px;
}
</style>
